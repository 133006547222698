import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragItem from './DragItem';
import DropZone from './DropZone';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { getCurrentWeekOfYear } from '../../utils/dateUtils';

const Create_New_Chart = () => {
  const [droppedItems, setDroppedItems] = useState([]);
  const [songs, setSongs] = useState([]);
  const [draggableStatus, setDraggableStatus] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [chartTitle, setChartTitle] = useState(getCurrentWeekOfYear());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Songs'));
        const songsData = querySnapshot.docs.map((doc) => {
          const song = { id: doc.id, ...doc.data() };
          const songName = song.ftartiste ? `${song.title} - ${song.artiste} ft. ${song.ftartiste}` : `${song.title} - ${song.artiste}`;
          return { ...song, name: songName };
        });
        setSongs(songsData);
        const initialStatus = {};
        songsData.forEach((song) => {
          initialStatus[song.id] = true;
        });
        setDraggableStatus(initialStatus);
      } catch (error) {
        setError('Failed to fetch songs. Please try again later.');
      }
    };
    fetchSongs();
  }, []);

  const handleDrop = (item) => {
    if (droppedItems.length < 20) {
      const [title, artiste] = item.name.split(' - ');
      const newItem = { ...item, title, artiste };
      setDroppedItems((prevItems) => [...prevItems, newItem]);
      setDraggableStatus((prevStatus) => ({
        ...prevStatus,
        [item.id]: false,
      }));
    } else {
      alert('You can only add up to 20 songs.');
    }
  };

  const handleRemoveItem = (index) => {
    const removedItem = droppedItems[index];
    const updatedItems = [...droppedItems];
    updatedItems.splice(index, 1);
    setDroppedItems(updatedItems);
    setDraggableStatus((prevStatus) => ({
      ...prevStatus,
      [removedItem.id]: true,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const filteredSongs = songs
    .filter(
      (song) =>
        song.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        song.artiste.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.title.localeCompare(b.title)); // Sort songs alphabetically

  const handlePreview = () => {
    if (droppedItems.length >= 3) {
      const droppedItemsWithId = droppedItems.map(item => ({ ...item, id: item.id }));
      navigate('/preview', { state: { songs: droppedItemsWithId, chartTitle } });
    }
  };

  return (
    <Box m="15px">
      <Helmet>
        <title>Create New Chart | Cubana Chart</title>
      </Helmet>
      <Header title="CREATE NEW CHART" subtitle="Create a New Chart" />
      <Box>
        <DndProvider backend={HTML5Backend}>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            sx={{
              border: '1px solid #ccc',
              padding: '20px',
              borderRadius: '5px',
              mb: '20px',
              color: '#000',
              backgroundColor: '#fff',
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                Chart Title
              </Typography>
              <TextField
                variant="outlined"
                value={chartTitle}
                onChange={(e) => setChartTitle(e.target.value)}
                fullWidth
                margin="normal"
                sx={{ border: '1px solid #ccc' }}
                InputProps={{
                  style: {
                    color: '#000',
                  },
                }}
              />
            </Box>
            <Box display="flex" justifyContent="space-around">
              <Box
                sx={{
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                  width: '45%',
                }}
              >
                <h3>Songs Bucket</h3>
                <TextField
                  label="Search Songs"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  fullWidth
                  margin="normal"
                  sx={{
                    borderRadius: '5px',
                    border: '1px solid #000',
                    '& .MuiOutlinedInput-root': {
                      color: '#000',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#000',
                    },
                  }}
                  InputProps={{
                    style: {
                      color: '#000',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon style={{ color: 'grey' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  sx={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                  }}
                >
                  {error ? (
                    <Box color="red">{error}</Box>
                  ) : (
                    filteredSongs.map((song) => (
                      <DragItem
                        key={song.id}
                        name={song.name}
                        title={song.title}
                        artiste={song.artiste}
                        songUrl={song.songUrl}
                        imageUrl={song.imageUrl}
                        isDraggable={draggableStatus[song.id]}
                        id={song.id}
                      />
                    ))
                  )}
                </Box>
              </Box>
              <Box
  sx={{
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px',
    width: '45%',
  }}
>
<h3>
    New Chart Bucket 
    <span style={{ fontSize: '16px', marginLeft: '8px' }}>({droppedItems.length})</span> 
  </h3>
  <DropZone onDrop={handleDrop} />
  {droppedItems.map((item, index) => (
    <Box
      key={index}
      sx={{
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        marginTop: '10px',
        backgroundColor: 'lightgrey',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p>{item.title} - {item.artiste}</p>
      <IconButton
        style={{
          border: '1px solid grey',
          padding: '3px',
          borderRadius: '5px',
          marginTop: '2px',
        }}
        onClick={() => handleRemoveItem(index)}
      >
        <DeleteIcon style={{ color: '#000' }} />
      </IconButton>
    </Box>
  ))}
</Box>

            </Box>
            <Box display="flex" justifyContent="end" mt="15px">
              <Button
                variant="contained"
                color="primary"
                onClick={handlePreview}
                disabled={droppedItems.length < 5}
              >
                Preview
              </Button>
            </Box>
          </Box>
        </DndProvider>
      </Box>
    </Box>
  );
};

export default Create_New_Chart;
